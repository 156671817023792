

/*

Install
in main.js

import {imagekit} from './functions/imagekit.js'
Vue.prototype.$imagekit = imagekit

*/

// Usage example $imagekit.resize(item.img, 300)


export const imagekit = {
    resize: (src, w, h) => {
        if (typeof(src) == "undefined" ) {
            return "";
        }
       var imgret = src.replace("https://pescatore.d40.it/", "");
       if (typeof(h) == "undefined" ) {
        return "https://ik.imagekit.io/vd5fu4nv7/tr:w-"+w+"/"+imgret;  
       } else {
        return "https://ik.imagekit.io/vd5fu4nv7/tr:w-"+w+",h-"+h+"/"+imgret;  
       }
       
     }
 }
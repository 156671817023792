import Vue from 'vue'
import VueRouter from 'vue-router'
import goTo from 'vuetify/lib/services/goto'
import Home from '../components/Home.vue'
import Glossario from '../components/Glossario.vue'
import GlossarioDetails from '../components/GlossarioDetails.vue'
import Percorso from '../components/Percorso.vue'
import PercorsoDetails from '../components/PercorsoDetails.vue'
import Informazioni from '../components/Informazioni.vue'
import Gallery from '../components/Gallery.vue'
import GalleryDetails from '../components/GalleryDetails.vue'
import LanguageSelection from '../components/LanguageSelection.vue'
import Games from '../components/Games.vue'
import QRScan from '../components/QRScan.vue'
import GameRunning from '../components/GameRunning.vue'
import GameResult from '../components/GameResult.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/glossario',
    name: 'Glossario',
    props: false,
    component: Glossario
  },
  {
    path: '/glossariodetails/:id',
    name: 'GlossarioDetails',
    props: true,
    component: GlossarioDetails
  },
  {
    path: '/percorso',
    name: 'Percorso',
    props: false,
    component: Percorso
  },
  {
    path: '/percorsodetails/:id',
    name: 'PercorsoDetails',
    props: true,
    component: PercorsoDetails
  },
  {
    path: '/informazioni',
    name: 'Informazioni',
    props: false,
    component: Informazioni
  },
  {
    path: '/gallery',
    name: 'Gallery',
    props: false,
    component: Gallery
  },
  {
    path: '/gallerydetails/:id',
    name: 'GalleryDetails',
    props: true,
    component: GalleryDetails
  },
  {
    path: '/languageselection',
    name: 'LanguageSelection',
    props: false,
    component: LanguageSelection
  },
  {
    path: '/games',
    name: 'Games',
    props: false,
    component: Games
  },
  {
    path: '/gamerunning',
    name: 'GameRunning',
    props: false,
    component: GameRunning
  },
  {
    path: '/gameresult/:points',
    name: 'GameResult',
    props: true,
    component: GameResult
  },
  {
    path: '/qrscan',
    name: 'QRScan',
    props: false,
    component: QRScan
  },
  
  
]

const router = new VueRouter({
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0

    if (to.hash) {
      scrollTo = to.hash
    } else if (savedPosition) {
      scrollTo = savedPosition.y
    }

    return goTo(scrollTo)
  },
  routes
})

router.beforeEach((to, from, next) => {
  
  if (localStorage.getItem('curlanguage') == null && (to.path.indexOf('languageselection') < 0)) {
    localStorage.setItem('lastpath', to.path);
    next({ path: '/languageselection'});
  } else {
    next();
  }
  
})

export default router

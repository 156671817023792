<template>
  <v-container fluid>
    <v-row >
      <v-col>
        <h1 class="light-blue--text text--darken-3 mb-4">
          {{ getContent().title }}
        </h1>
        <h2 class="light-blue--text text--darken-3 mb-4">
          {{ getContent().subtitle }}
        </h2>
        <p v-html="getContent().description"></p>
      </v-col>
    </v-row>

    <v-row >
      <v-col>
        <h2 class=" light-blue--text text--darken-3 mb-4" v-html="getContent().howtogetthere">
          
        </h2>
      </v-col>
    </v-row>

    <v-row >
      <v-col>
        <v-img :src="getContent().imagemap"> </v-img>
      </v-col>
    </v-row>

    <v-row >
      <v-col>
        <h2 class="light-blue--text text--darken-3 mb-4">
          {{ getContent().timetable.title }}
        </h2>
        <p v-html="getContent().timetable.subtitle"></p>
      </v-col>
    </v-row>

    <v-row >
      <v-col>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  {{ getContent().timetable.periodlabel }}
                </th>
                <th class="text-left">
                  {{ getContent().timetable.timelabel }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in getContent().timetable.table"
                :key="item.period"
              >
                <td>{{ item.period }}</td>
                <td>{{ item.time }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    <v-row >
      <v-col>
        <h2 class="light-blue--text text--darken-3 mb-4">
          {{ getContent().contactslabel }}
        </h2>
        <p v-html="getContent().contacts"></p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Informazioni",
  data() {
    return {
      scheda: {
        titolo: "titolo",
      },
    };
  },
  mounted() {
    this.$store.dispatch("setCurPageAction", {
      title: "Info",
      detail: false,
      back: "/",
      bottomNavigation: true,
    });
  },
  methods: {
    getCurLanguage() {
      return this.$store.state.curlanguage;
    },
    getContent() {
      return this.$store.state.content.info;
    },
    redirect(path) {
      if (this.$route.path != path) {
        this.$router.push(path);
      }
    },
  },
};
</script>
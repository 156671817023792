// route: https://pescatore.d40.it/jsonfeed?p_p_id=Configurable&p_p_lifecycle=2&p_p_resource_id=json&_Configurable_jsonParams=%7B%22structureKey%22:%222904848%22%7D&444

function myStringToIntDef(stringval, defaultval) {
    const parsed = parseInt(stringval);
    if (isNaN(parsed)) { return defaultval; }
    return parsed;
}

function myCalcUrl(relativeurl, width) {
    if (typeof (width) == 'undefined') {
        if (relativeurl !== "") {
            return 'https://pescatore.d40.it' + relativeurl;
        } else {
            return "";
        }

    } else if (width < 10) {
        if (relativeurl !== "") {
            return 'https://pescatore.d40.it' + relativeurl;
        } else {
            return "";
        }
    } else {
        if (relativeurl !== "") {
            return 'https://pescatore.d40.it' + relativeurl;
            //return 'https://d28r45jypu6nt9.cloudfront.net/o/d40/img/w_'+width+'/https.pescatore.d40.it/' + relativeurl;
        } else {
            return "";
        }
        
    }


    // https://d28r45jypu6nt9.cloudfront.net/o/d40/img/w_$width/https.mdg-mcf.d40.it


}

function myGetTypeFromFileName(fileName) {

    var extension = fileName.split('.').pop();

    var typeMap = {
        'gif': "image",
        'jpeg': "image",
        'jpg': "image",
        'png': "image",
        'tif': "image",
        'tiff': "image",
        'wbmp': "image",
        'ico': "image",
        'webp': "image",
        'svg': "svg",
        'mp3': "audio",
        'ogg': "audio",
        'ra': "audio",
        'mid': "midi",
        'midi': "midi",
        'kar': "midi",
        '3gpp': "video",
        '3gp': "video",
        'mpeg': "video",
        'mpg': "video",
        'mov': "video",
        'wmv': "video",
        'avi': "video",
        'm4v': "video",
        'mp4': "video",
    }

    if (typeof (typeMap[extension]) == "undefined") {
        return "";
    } else {
        return typeMap[extension];
    }

}

export let mapping = {
    //module.exports = {
    home(lrdoc) {
        var doc = lrdoc.docs[0].contentJSON;

        var sections = [];

        for (var i = 0; i < doc.sections.length; i++) {
            var as = doc.sections[i];
            var imglist = [];
            if (typeof (as.sectionImages.length) == 'number') {
                for (var j = 0; j < as.sectionImages.length; j++) {
                    var img = {
                        "img": myCalcUrl(as.sectionImages[j].sectionImageUrl, 300),
                        "title": as.sectionImages[j].sectionImageTitle,
                        "subtitle": as.sectionImages[j].sectionImageSubTitle,
                        "link": as.sectionImages[j].sectionImageLink
                    }
                    imglist.push(img);
                }
            }
            sections.push({
                "title": as.sectionTitle,
                "description": as.sectionDescription,
                "bgcolor": as.sectionBGColor,
                "link": as.sectionLink,
                "linktitle": as.sectionLinkTitle,
                "imglist": imglist
            });
        }

        var lis = doc.insights;
        var items = [];
        if (typeof (lis.insightsImages.length) == 'number') {
            for (var x = 0; x < lis.insightsImages.length; x++) {
                var item = {
                    "title": lis.insightsImages[x].insightsImageTitle,
                    "subtitle": lis.insightsImages[x].insightsImageSubtitle,
                    "img": myCalcUrl(lis.insightsImages[x].insightsImageUrl, 300),
                    "link": lis.insightsImages[x].insightsImageLink,
                }
                items.push(item);
            }
        }

        var insights = {
            "title": lis.insightsTitle,
            "items": items,
        };

        var outdoc = {
            "title": doc.title,
            "description": doc.description,
            "bannerimg": myCalcUrl(doc.bannerimg, 600),
            "sections": sections,
            "insights": insights,
        }

        return outdoc;

    },
    route(lrdoc) {
        var doc = lrdoc.docs[0].contentJSON;
        var scenelist = [];

        for (var i = 0; i < doc.scenelist.length; i++) {
            var as = doc.scenelist[i];
            var stories = [];
            // stories
            if (typeof (as.contentJSON.stories.length) == 'number') {
                for (var j = 0; j < as.contentJSON.stories.length; j++) {
                    var story = {
                        "title": as.contentJSON.stories[j].storyTitle,
                        "subtitle": as.contentJSON.stories[j].storySubtitle,
                        "img": myCalcUrl(as.contentJSON.stories[j].storyImg, 300),
                        "audio": myCalcUrl(as.contentJSON.stories[j].storyAudio),
                        "audiotitle": as.contentJSON.stories[j].storyAudioTitle
                    }
                    stories.push(story);
                }
            }

            // gallery
            var galleryImages = [];
            if (typeof (as.contentJSON.gallery.galleryImages.length) == 'number') {
                for (var gi = 0; gi < as.contentJSON.gallery.galleryImages.length; gi++) {
                    galleryImages.push({
                        "title": "**NOT DEFINED**",
                        "img": myCalcUrl(as.contentJSON.gallery.galleryImages[gi], 600)
                    });
                }
            }
            var gallery = {
                "title": as.contentJSON.gallery.galleryTitle,
                "subtitle": as.contentJSON.gallery.gallerySubtitle,
                "images": galleryImages
            };

            // video
            var video = {
                "title": as.contentJSON.video.videoTitle,
                "imgbanner": myCalcUrl(as.contentJSON.video.videoImgBannerUrl, 600),
                "video": myCalcUrl(as.contentJSON.video.videoDocumentUrl),
                "type": as.contentJSON.video.videoType,
                "link": as.contentJSON.video.videoLink,
            };

            // map
            var map = {
                "lat": as.contentJSON.latlng.latitude,
                "lng": as.contentJSON.latlng.longitude,
                "mapimg": as.contentJSON.mapImg
            };

            // closeto
            var closetoItems = [];
            if (typeof (as.contentJSON.closeTo.closeToItems.length) == 'number') {
                for (var ci = 0; ci < as.contentJSON.closeTo.closeToItems.length; ci++) {
                    closetoItems.push({
                        "img": myCalcUrl(as.contentJSON.closeTo.closeToItems[ci].closeToSceneImage, 300),
                        "title": as.contentJSON.closeTo.closeToItems[ci].closeToSceneTitle,
                        "link": as.contentJSON.closeTo.closeToItems[ci].articleId,
                        "linktitle": as.contentJSON.closeTo.closeToItems[ci].closeToSceneLinkTitle
                    });
                }
            }
            var closeto = {
                "title": as.contentJSON.closeTo.closeToTitle,
                "bgcolor": as.contentJSON.closeTo.closeToBgColor,
                "items": closetoItems
            };

            scenelist.push({
                "id": as.id,
                "code": as.contentJSON.code,
                "title": as.contentJSON.title,
                "img": myCalcUrl(as.contentJSON.img, 300),
                "bannerimg": myCalcUrl(as.contentJSON.bannerImg, 600),
                "listdesc": as.contentJSON.listadesc,
                "description": as.contentJSON.description,
                "storiestitle": as.contentJSON.storiesTitle,
                "storiesbgcolor": as.contentJSON.storiesbgcolor,
                "stories": stories,
                "gallery": gallery,
                "video": video,
                "map": map,
                "closeto": closeto,
            });
        }

        var outdoc = {
            "title": doc.title,
            "description": doc.description,
            "bannerimg": myCalcUrl(doc.bannerimg, 600),
            "scenelist": scenelist
        }

        return outdoc;

    },
    gallery(lrdoc) {
        var doc = lrdoc.docs[0].contentJSON;
        var items = [];
        for (var i = 0; i < doc.items.length; i++) {
            var ai = doc.items[i];
            var images = [];
            // itemImages
            if (typeof (ai.itemImages.length) == 'number') {
                for (var j = 0; j < ai.itemImages.length; j++) {
                    var itemImage = {
                        "title": "",
                        "img": myCalcUrl(ai.itemImages[j], 600),
                    }
                    images.push(itemImage);
                }
            }
            var item = {
                "title": ai.itemTitle,
                "subtitle": ai.itemSubtitle,
                "img": myCalcUrl(ai.itemImageUrl, 600),
                "description": ai.itemDescription,
                "images": images
            }
            items.push(item);
        }

        var outdoc = {
            "title": doc.title,
            "description": doc.description,
            "items": items
        }

        return outdoc;

    },
    glossary(lrdoc, items) {
        var doc = lrdoc.docs[0].contentJSON;

        var outdoc = {
            "title": doc.title,
            "description": doc.description,
            "items": items
        }
        return outdoc;
    },
    glossaryItems(lrdoc) {

        var items = [];

        for (var i = 0; i < lrdoc.docs.length; i++) {
            var doc = lrdoc.docs[i];

            var images = [];
            for (var j = 0; j < doc.contentJSON.gallery.galleryImages.length; j++) {
                images.push({
                    "title": "",
                    "img": myCalcUrl(doc.contentJSON.gallery.galleryImages[j], 600)
                });
            }
            var gallery = {
                "title": doc.contentJSON.gallery.galleryTitle,
                "subtitle": doc.contentJSON.gallery.gallerySubtitle,
                "images": images,

            };

            var video = {
                "bannerimg": myCalcUrl(doc.contentJSON.video.videoImgBannerUrl, 600),
                "title": doc.contentJSON.video.videoTitle,
                "video": myCalcUrl(doc.contentJSON.video.videoDocumentUrl),
                "videolilnk": doc.contentJSON.video.videoLink,
                "type": doc.contentJSON.video.videoType
            };

            var item = {
                "id": doc.articleId,
                "title": doc.contentJSON.title,
                "img": myCalcUrl(doc.contentJSON.imgUrl, 600),
                "bannerimg": myCalcUrl(doc.contentJSON.bannerImgUrl, 600),
                "listdesc": doc.contentJSON.listadesc,
                "description": doc.contentJSON.description,
                "gallery": gallery,
                "video": video,
            }

            items.push(item);
        }


        return items;
    },
    information(lrdoc) {
        var doc = lrdoc.docs[0].contentJSON;
        var timeTable = [];
        var ttobj = doc.timetable[0]
        for (var i = 0; i < ttobj.table.length; i++) {
            var tt = ttobj.table[i];
            var ti = {
                "period": tt.period,
                "time": tt.time,
            }
            timeTable.push(ti);
        }

        var outdoc = {
            "title": doc.title,
            "subtitle": doc.subTitle,
            "description": doc.description,
            "howtogetthere": doc.howToGetThere,
            "imagemap": myCalcUrl(doc.mapImgUrl, 600),
            "latlng": doc.latlng,
            "timetable": {
                "title": ttobj.timetableTitle,
                "subtitle": ttobj.timetableSubtitle,
                "periodlabel": ttobj.timetablePeriodLabel,
                "timelabel": ttobj.timetableTimeLabel,
                "table": timeTable,
            },
            "contactslabel": doc.contactsLabel,
            "contacts": doc.contacts

        }

        return outdoc;
    },
    game(lrdoc, gameList) {
        var doc = lrdoc.docs[0].contentJSON;

        var outdoc = {
            "title": doc.title,
            "subtitle": doc.subtitle,
            "correctanswer": doc.correctanswer,
            "correctanswerpoints": doc.correctanswerpoints,
            "wronganswer": doc.wronganswer,
            "wronganswerpoints": doc.wronganswerpoints,
            "back": doc.back,
            "next": doc.next,
            "endpoints": doc.endpoints,
            "description": doc.description,
            "img": myCalcUrl(doc.img, 600),
            "list": gameList
        }
        return outdoc;
    },
    gameList(lrdoc) {
        var list = [];

        for (var i = 0; i < lrdoc.docs.length; i++) {
            var doc = lrdoc.docs[i];

            var questions = [];
            for (var j = 0; j < doc.contentJSON.questions.length; j++) {
                var quest = doc.contentJSON.questions[j]
                var answers = [];

                for (var y = 0; y < quest.questAnswers.length; y++) {
                    var qa = quest.questAnswers[y];
                    answers.push({
                        "title": qa.answerTitle,
                        "points": myStringToIntDef(qa.answerPoints, 0),
                    });
                }

                questions.push({
                    "nr": quest.questPos,
                    "title": quest.questTitle,

                    "img": myCalcUrl(quest.questImageUrl, 600),
                    "description": quest.questDescription,
                    "answers": answers
                });
            }

            // Tutti i giochi a parte quello da fare nel magazzino delle reti
            if (doc.articleId != "2908219") {
                list.push({
                    "id": doc.articleId,
                    "title": doc.contentJSON.gameTitle,
                    "subtitle": doc.contentJSON.gameSubtitle,
                    "img": myCalcUrl(doc.contentJSON.gameImageUrl, 600),
                    "answersLayout": doc.contentJSON.answersLayout,
                    "items": questions
                });
            }
            

        }


        return list;
    },
    mediaStation(lrdoc) {
        var devices = [];

        for (var i = 0; i < lrdoc.docs.length; i++) {
            var doc = lrdoc.docs[i];

            var media = [];

            for (var j = 0; j < doc.contentJSON.mediaList.length; j++) {
                media.push({
                    "id": doc.contentJSON.mediaList[j].mediaFileRealTitle,
                    "title": doc.contentJSON.mediaList[j].mediaFileTitle,
                    "subtitle": "",
                    "type": myGetTypeFromFileName(doc.contentJSON.mediaList[j].mediaFileRealTitle),
                    "remoteurl": myCalcUrl(doc.contentJSON.mediaList[j].mediaFileUrl),
                    "version": "",
                    "modified": "",
                    "url": ""
                })
            }

            devices.push({
                "device": doc.contentJSON.postazione,
                "type": doc.contentJSON.dispositivo,
                "devicetype": doc.contentJSON.devicetype,
                "url": doc.contentJSON.urlinfopoint,
                "ip": doc.contentJSON.indirizzoip,
                "location": {
                    "area": doc.contentJSON.area,
                    "placename": "",
                    "roomname": "",
                    "floor": "",
                    "position": doc.contentJSON.posizione,
                },
                "interaction": {
                    "name": doc.contentJSON.nomelogico,
                    "type": doc.contentJSON.InteractionType,
                    "volume": doc.contentJSON.volume,
                    "deltaPIR": doc.contentJSON.deltaPIR,
                    "media": media
                },
                "dateModified": doc.modifiedDate
            });
        }


        return {
            "id": "1",
            "name": "Casa del Pescatore",
            "version": "1.0",
            "devices": devices
        };
    },
    generalConfig(lrdoc) {
        var doc = lrdoc.docs[0];

        var vocabularyId = "";
        var vocabularyName = "";
        var categoryId = "";
        var categoryName = "";

        if (doc.groupedCategories.length > 0) {
            vocabularyId = doc.groupedCategories[0].vocabularyId;
            vocabularyName = doc.groupedCategories[0].vocabularyName;
            if (doc.groupedCategories[0].categories.length > 0) {
                categoryId = doc.groupedCategories[0].categories[0].categoryId;
                categoryName = doc.groupedCategories[0].categories[0].name;
            }
        }

        return {
            "imgInfoTitle": doc.contentJSON.imgInfoTitle,
            "tagSceneFilter": doc.contentJSON.tagSceneFilter,
            "imgInfoUrl": myCalcUrl(doc.contentJSON.imgInfoUrl),
            "version": doc.contentJSON.version,
            "info": doc.contentJSON.info,
            "docversion": doc.version,
            "modifiedDate": doc.modifiedDate,
            "vocabularyId": vocabularyId,
            "vocabularyName": vocabularyName,
            "categoryId": categoryId,
            "categoryName": categoryName,
        };
    }
}
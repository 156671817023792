import { it } from 'vuetify/lib/locale'

export default {
  ...it,
  home: "Home",
  glossario: "Glossario",
  percorso: "Percorso",
  informazioni: "Info",
  gallery: "Gallery",
  games:"Giochi",
  ascolta:"ASCOLTA",
  prossima: "PROSSIMA",
  gioca: "Gioca", 
  resultTitle:"Hai totalizzato %P punti",
  namespace: {
    key3: "key 3 internationalization"
  }
}
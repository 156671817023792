import { en } from 'vuetify/lib/locale'

export default {
  ...en,

  home: "Home",
  glossario: "Glossary",
  percorso: "Route",
  informazioni: "Info",
  gallery: "Gallery",
  games:"Games",
  ascolta:"LISTEN",
  prossima: "NEXT",
  gioca: "Play",
  resultTitle:"Total points %P",
  namespace: {
    key3: "key 3 internationalization"
  }
}
<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-img contain :src="$imagekit.resize(getContent().bannerimg, 600)"></v-img>
      </v-col>
    </v-row>

    <v-row >
      <v-col>
        <h2 class="light-blue--text text--darken-3 mb-4">
          {{ getContent().title }}
        </h2>
        <p v-html="getContent().description"></p>
      </v-col>
    </v-row>

   
    <!-- immagini -->
    <v-row v-show="getContent().gallery.title != ''" >
      <v-col>
        <h2 class="light-blue--text text--darken-3">
          {{ getContent().gallery.title }}
        </h2>
        <h2 class="light-blue--text text--darken-3">
          {{ getContent().gallery.subtitle }}
        </h2>
      </v-col>
    </v-row>

    <v-row  v-show="getContent().gallery.title != ''">
      <v-col>
        <v-carousel class="rounded-lg" :show-arrows="getContent().gallery.images.length > 1" hide-delimiters height="250">
          <v-carousel-item
            max-width="100%"
            contain
            v-for="(item, i) in getContent().gallery.images"
            :key="i"
            :src="$imagekit.resize(item.img, 600)"
          >
          
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>

    <!-- video -->
    <v-row v-show="getContent().video.title != ''" >
      <v-col>
        <h2 class="light-blue--text text--darken-3">
          {{ getContent().video.title }}
        </h2>
      </v-col>
    </v-row>

    <v-row v-show="getContent().video.title != ''" >
      <v-col>
        <!-- h2 class="light-blue--text text--darken-3">
          <v-img :src="getContent().video.bannerimg" class="rounded-lg"></v-img>
        </h2 -->
        <video
            v-show="getContent().video.title != ''"
            class="rounded-lg"
            width="100%"
            preload="metadata"
            controls
            :src="getVideoWithThumbnail(getContent().video.video)"
          >
          </video>
      </v-col>
    </v-row>

    <!-- code>
      <pre>{{ JSON.stringify(getContent(), null, 2) }}</pre>
    </code -->
  </v-container>
</template>

<script>
//import moment from "moment";

export default {
  name: "GlossarioDetails",
  data() {
    return {
      selphoto: null,
    };
  },
  props: ["id"],
  mounted() {
    this.$store.dispatch("setCurPageAction", {
      title: "GlossarioDetails",
      detail: true,
      back:"/glossario",
      bottomNavigation: true,
    });
  },
  methods: {
    getCurLanguage() {
      return this.$store.state.curlanguage;
    },
    getContent() {
      //return this.$store.getters.getGlossaryById(this.id);
      return this.$store.getters.getGlossaryById(this.id);
      //return this.$store.state.content.items.scenelist[this.id];
    },
    getVideoWithThumbnail(video) {
      return video +'#t=0.001';
    }
  },
};
</script>
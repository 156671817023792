import { de } from 'vuetify/lib/locale'

export default {
  ...de,
  home: "Home",
  glossario: "Glossar",
  percorso: "Weg",
  informazioni: "Info",
  gallery: "Gallery",
  games:"Spiele",
  ascolta:"ABHÖREN",
  prossima: "NÄCHSTE",
  gioca: "Spiel",
  resultTitle:"Sie haben %P Punkte erzielt",
  namespace: {
    key3: "key 3 internationalization"
  }
}
<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-img
          contain
          :src="getContent().bannerimg"
        ></v-img>
      </v-col>
      
    </v-row>
    <v-row>
      <v-col>
        <h2 class="light-blue--text text--darken-3 mb-4">
          {{ getContent().title }}
        </h2>
        <p v-html="getContent().description"></p>
      </v-col>
    </v-row>
    <!-- Sections -->
    <v-row>
      <v-col v-for="(item, i) in getContent().sections" :key="i" cols="12">
        <v-card :color="item.bgcolor" dark>
          <v-card-title class="text-h5">{{ item.title }}</v-card-title>
          <v-card-text v-html="item.description"></v-card-text>

          <v-card-text v-show="item.imglist.length != 0">
            <v-row>
              <v-col v-for="(imgitem, y) in item.imglist" :key="y" cols="6">
                <v-img :src="imgitem.img"></v-img>
                <div class="text-h6 pt-2">{{ imgitem.title }}</div>
                <div>{{ imgitem.subtitle }}</div>
                
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions v-show="item.link != ''">
            <v-btn text @click="redirect(item.link)">
              <v-icon>mdi-arrow-right</v-icon>
              {{ item.linktitle }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <!-- Insights TODO da inserire-->
    <!-- 
    <v-row class="mx-6">
      <v-col cols="12"
        ><h2 class="light-blue--text text--darken-3">
          {{ getContent().insights.title }}
        </h2></v-col
      >
    </v-row>
    <v-row class="mb-8">
      <v-col
        v-for="(item, i) in getContent().insights.items"
        :key="i"
        cols="12"
      >
        <v-card dark>
          <v-img
            :src="item.img"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            height="200px"
          >
            <v-card-title class="text-h6 pb-0">{{ item.title }}</v-card-title>
            <v-card-actions class="pa-0">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ item.subtitle }}</v-list-item-title>
                </v-list-item-content>
                <v-row align="center" justify="end" class="px-2"
                  ><v-icon>mdi-arrow-right</v-icon></v-row
                >
              </v-list-item>
            </v-card-actions>
          </v-img>
        </v-card>
      </v-col>
    </v-row>
    -->
  </v-container>
</template>



<script>
export default {
  name: "Home",

  /*
    $store.dispatch("setCurPageAction", {
      title: "Le Rassegne",
      detail: false
    })
    */

  data: () => ({
    title: "home",
  }),
  mounted() {
    this.$store.dispatch("setCurPageAction", {
      title: "Home",
      detail: false,
      bottomNavigation: true,
    });
  },
  methods: {
    getCurLanguage() {
      return this.$store.state.curlanguage;
    },
    getContent() {
      return this.$store.state.content.home;
    },
    redirect(path) {
      if (this.$route.path != path) {
        this.$router.push(path);
      }
    },
  },
};
</script>

<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-img contain :src="$imagekit.resize(getContent().bannerimg, 600)"></v-img>
      </v-col>
    </v-row>

    <v-row >
      <v-col>
        <h2 class="light-blue--text text--darken-3 mb-4">
          {{ getContent().title }}
        </h2>
        <p v-html="getContent().description"></p>
      </v-col>
    </v-row>

    <!-- Stories -->
    <v-row v-show="getContent().storiestitle != ''">
      <v-col cols="12">
        <v-card class="pa-2" :color="getContent().storiesbgcolor" dark>
          <v-card-title class="text-h5">{{
            getContent().storiestitle
          }}</v-card-title>

          <v-card-text v-show="getContent().stories.length != 0">
            <v-row v-for="(item, y) in getContent().stories" :key="y">
              <v-col cols="6">
                <v-img contain :src="$imagekit.resize(item.img, 600)" class="rounded-lg"></v-img>
              </v-col>
              <v-col cols="6">
                <div class="pt-2">{{ item.title }}</div>
                <div>{{ item.subtitle }}</div>
                <vuetify-miniaudio
                  :file="item.audio"
                  color="success"
                ></vuetify-miniaudio>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- immagini -->
    <v-row v-show="getContent().gallery.title != ''" >
      <v-col>
        <h2 class="light-blue--text text--darken-3">
          {{ getContent().gallery.title }}
        </h2>
        <h2 class="light-blue--text text--darken-3">
          {{ getContent().gallery.subtitle }}
        </h2>
      </v-col>
    </v-row>

    <v-row v-show="getContent().gallery.title != ''">
      <v-col>
        <v-carousel
          :show-arrows="true"
          hide-delimiters
          height="250"
          class="rounded-lg"
        >
          <v-carousel-item
            max-width="100%"
            contain
            v-for="(item, i) in getContent().gallery.images"
            :key="i"
            :src="$imagekit.resize(item.img, 600)"
          ></v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>

    <!-- video -->
    <v-row v-show="getContent().video.title != ''" >
      <v-col>
        <h2 class="light-blue--text text--darken-3">
          {{ getContent().video.title }}
        </h2>
      </v-col>
    </v-row>

    <v-row v-show="getContent().video.title != ''" >
      <v-col>
        <h2 class="light-blue--text text--darken-3"></h2>
        <!-- v-img :src="getContent().video.imgbanner" class="rounded-lg"></v-img -->
        
         <video
           class="rounded-lg"
            v-show="getContent().video.title != ''"
            width="100%"
            preload="metadata"
            controls
            :src="getVideoWithThumbnail(getContent().video.video)"
          >
          </video>
      </v-col>
    </v-row>

    <!-- mappa immagine -->
    <v-row v-show="getContent().map.mapimg != ''" class="my-6">
      <v-col cols="12" class="pa-0 my-4">
        <v-img contain :src="getContent().map.mapimg"></v-img>
      </v-col>
    </v-row>

    <!-- Close to ... TODO -->
    <!-- 
    <v-row class="my-4" v-show="getContent().closeto.items.length != 0">
      <v-col cols="12">
        <v-card class="pa-2" :color="getContent().closeto.bgcolor" dark>
          <v-card-title class="text-h6">{{
            getContent().closeto.title
          }}</v-card-title>

          <v-card-text v-show="getContent().closeto.length != 0">
            <v-row v-for="(item, y) in getContent().closeto.items" :key="y">
              <v-col cols="6">
                <v-img :src="item.img" class="rounded-lg"></v-img>
              </v-col>
              <v-col cols="6">
                <div class="pt-2 text-h6">{{ item.title }}</div>

                <v-btn color="white" class="mt-2 black--text" elevation="0">
                  {{ item.linktitle }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    -->

    <!-- code>
      <pre>{{ JSON.stringify(getContent(), null, 2) }}</pre>
    </code -->
  </v-container>
</template>

<script>
//import moment from "moment";

import VuetifyMiniaudio from "./VuetifyMiniaudio.vue";

export default {
  name: "PercorsoDetails",
  components: {
    VuetifyMiniaudio,
  },
  data() {
    return {
      selphoto: null,
      audioFinish: false,
    };
  },
  props: ["id"],
  mounted() {
    this.$store.dispatch("setCurPageAction", {
      title: "PercorsoDetails",
      detail: true,
      back:"/percorso",
      bottomNavigation: true,
    });
  },
  methods: {
    getCurLanguage() {
      return this.$store.state.curlanguage;
    },
    getContent() {
      return this.$store.state.content.route.scenelist[this.id];
    },
    getVideoWithThumbnail(video) {
      return video +'#t=0.001';
    }
  },
};
</script>
<template>
  <v-container fluid>
    <v-row >
      <v-col>
        <h2 class="light-blue--text text--darken-3 mb-4">
          {{ getContent().title }}
        </h2>
        <p v-html="getContent().description"></p>
      </v-col>
    </v-row>
    <v-row >
      <v-col>
        <template v-for="(item, index) in getContentGrouped()">
          <div :key="index">
            <v-row  justify="center" align="center">
              <v-col cols="2">
                <v-chip
                  :key="index"
                  class=" ma-0"
                  color="light-blue darken-3 white--text"
                  label
                  ><h2>{{ item.group }}</h2></v-chip
                >
              </v-col>
              <v-col>
                <v-divider></v-divider>
              </v-col>
            </v-row>

            <template v-for="subitem in item.children">
              <div :key="subitem.id">
                <h2
                  @click="redirect(subitem.id)"
                  class=" light-blue--text text--darken-3"
                >
                  {{ subitem.title }}
                </h2>
                <p :key="subitem.id" @click="redirect(subitem.id)">
                  {{ subitem.listdesc }}
                </p>
              </div>
            </template>
          </div>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Glossario",
  data() {
    return {
      scheda: {
        titolo: "titolo",
      },
    };
  },
  mounted() {
    this.$store.dispatch("setCurPageAction", {
      title: 'glossario',
      detail: false,
      back:"/",
      bottomNavigation: true,
    });
  },
  methods: {
    getCurLanguage() {
      return this.$store.state.curlanguage;
    },
    getContent() {
      return this.$store.state.content.glossary;
    },
    getContentGrouped() {
      return this.$store.state.content.glossarygroup;
    },
    redirect(id) {
      this.$router.push("/glossariodetails/" + id);
    },
  },
};
</script>
<template>
  <v-container fluid>
    <v-row >
      <v-col>
        <h2 class="light-blue--text text--darken-3 mb-4">
          {{ getContent().title }}
        </h2>
        <p v-html="getContent().description"></p>
      </v-col>
    </v-row>


    <v-row >
      <v-col
        v-for="(item, index) in getContent().items"
        :key="index"
        class="d-flex child-flex"
        cols="6"
      >

      <v-card @click="redirect(index)" elevation="2" >
        <v-img :src="$imagekit.resize(item.img, 300)" contain >
          
        </v-img>
        <v-card-title class="justify-center light-blue--text text--darken-3 align-end  ">
          {{item.title}}
        </v-card-title>
       
      </v-card>

      </v-col>
    </v-row>

  </v-container>
</template>

<script>
export default {
  name: "Gallery",
  data() {
    return {
      scheda: {
        titolo: "titolo",
        
      },
    };
  },
  mounted() {
    this.$store.dispatch("setCurPageAction", {
      title: "Gallery",
      detail: false,
      back:"/",
      bottomNavigation: true,
    });
  },
  methods: {
    getCurLanguage() {
      return this.$store.state.curlanguage;
    },
    getContent() {
      return this.$store.state.content.gallery;
    },
    redirect(id) {
      this.$router.push("/gallerydetails/" + id);
    },
  },
};
</script>
import Vue from 'vue'
//import vuex from 'vuex'
import App from './App.vue'
import './registerServiceWorker'
import vuetify from './plugins/vuetify'
import router from './router'
import { store } from './store'

import VueMatomo from 'vue-matomo'
Vue.use(VueMatomo, {
  // Configure your matomo server and site by providing
  host: 'https://matomo.suggesto.eu/',
  siteId: 45,
  trackerFileName: 'matomo',
  router: router,
  enableLinkTracking: true,
  requireConsent: false,
  trackInitialView: true,
  disableCookies: false,
  requireCookieConsent: false,
  enableHeartBeatTimer: false,
  heartBeatTimerInterval: 15,
  debug: false,
  userId: 'webapp',
  cookieDomain: undefined,
  domains: undefined,
  preInitActions: [],
  trackSiteSearch: false,
  crossOrigin: undefined,
});
//import VuetifyMiniaudio from './components/VuetifyMiniaudio';

import {imagekit} from './functions/imagekit.js'
Vue.prototype.$imagekit = imagekit

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  //VuetifyMiniaudio,
  created() {
    this.$store.dispatch('initializeStore', vuetify);
  },
  mounted(){
    this.$store.dispatch("checkForUpdates");
    this.$store.dispatch("setCurLanguageAndLoadData", '');
  },
  render: h => h(App)
}).$mount('#app')

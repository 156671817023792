<template>
  <v-container fill-height>
    <v-row>
      <v-col>
        <div id="reader" ></div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Html5QrcodeScanner } from "html5-qrcode";

export default {
  name: "QRScan",
  data() {
    return {
      view: "start",
    };
  },
  mounted() {
    let html5QrcodeScanner = new Html5QrcodeScanner(
      "reader",
      {verbose:false, fps: 10, qrbox: { width: 250, height: 250 } },
       
    );
    html5QrcodeScanner.render(
      function onScanSuccess(decodedText, decodedResult) {
        alert("onScanSuccess 2: " + decodedText + ' ('+decodedResult+')');
        // handle the scanned code as you like, for example:
        console.log(`Code matched = ${decodedText}`, decodedResult);
      },

      function onScanFailure(error) {
        //alert("onScanFailure", error);
        // handle scan failure, usually better to ignore and keep scanning.
        // for example:
        console.warn(`Code scan error = ${error}`);
      }
    );
  },
  methods: {},
};
</script>
<template>
  <v-container fill-height>
    <v-row class="mt-8 mx-2" justify="center">
      <v-col style="text-align: center">
        <h2>{{ getContent().subtitle }}</h2>
      </v-col>
    </v-row>

    <v-row>
      <v-col v-for="(item, index) in getContent().list" :key="index" cols="12">
        <v-card @click="setCurGame(index)">
          <v-img
            contain
            :src="$imagekit.resize(item.img, 600, 600)"
            aspect-ratio="1"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <v-card-title>
            {{ item.title }}
          </v-card-title>
          <v-card-text class="text-center">
            {{ item.subtitle }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn rounded block>{{$vuetify.lang.t("$vuetify.gioca")}} </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <!-- v-row class="mx-2 mt-0">
      <v-col>
        <v-btn
          @click="goHome()"
          x-large
          elevation="2"
          color="secondary"
          class="white--text"
          ><v-icon class="ma-2"> mdi-arrow-left </v-icon> Indietro</v-btn
        >
      </v-col>
    </v-row -->
  </v-container>
</template>

<script>
export default {
  name: "Games",
  data() {
    return {
      view: "start",
      scheda: {
        titolo: "titolo",
      },
      gameSel: {},
      gameStatus: {},
    };
  },
  mounted() {
    this.$store.dispatch("setCurPageAction", {
      title: "Games",
      detail: false,
      back: "/",
      bottomNavigation: false,
    });
  },
  methods: {
    getTr(word) {
      return this.$store.state.tr[this.$store.state.curlanguage][word];
    },
    getCurLanguage() {
      return this.$store.state.curlanguage;
    },
    getContent() {
      //return this.$store.state.content.games;
      console.log("games");
      return this.$store.getters.getGames;
    },
    getGameList() {
      return this.$store.getters.getGameList;
    },
    setCurGame(index) {
      this.$store.dispatch("setCurGameByIndex", { index: index });
      this.$router.push("/gamerunning/");
    },
    goHome() {
      //this.$router.push("/");
      this.$router.replace({ path: "/" });
    },
  },
};
</script>
<template>
  <v-container fill-height >
    <v-row>
      <v-col cols="12" sm="4" md="4">
        <v-btn
          color="secondary"
          block
          elevation="2"
          x-large
          @click="changeLanguage('it')"
          >ITALIANO</v-btn
        >
      </v-col>
      <v-col cols="12" sm="4" md="4">
        <v-btn
          color="secondary"
          block
          elevation="2"
          x-large
          @click="changeLanguage('de')"
          >DEUTSCH</v-btn
        >
      </v-col>
      <v-col cols="12" sm="4" md="4">
        <v-btn
          color="secondary"
          block
          elevation="2"
          x-large
          @click="changeLanguage('en')"
          >ENGLISH</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "LanguageSelection",

  data: () => ({
    title: "home",
  }),
  mounted() {
    this.$store.dispatch("setCurPageAction", {
      title: "LanguageSelection",
      detail: false,
      back: "/",
      bottomNavigation: false,
    });
  },
  methods: {
    changeLanguage(item) {
      this.$store.dispatch("setCurLanguageAndLoadData", item);
      var lastpath = localStorage.getItem("lastpath");
      this.$router.push(lastpath);
    },
  },
};
</script>

import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import it from './it'
import en from './en' 
import de from './de'

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: {
            it,
            en,
            de,
        },
        current: 'it'
    },
    theme: {
        themes: {
            light: {
                primary: '#F5F5F5',
                secondary: '#1E75A0',
                accent: '#8c9eff',
                error: '#b71c1c',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',
                
            },
            dark: {
                primary: '#F5F5F5',
                secondary: '#1E75A0',
                accent: '#8c9eff',
                error: '#b71c1c',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107'
            },
        },
    },
});

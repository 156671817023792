<template>
  <v-container fluid>
    <v-dialog v-model="zoomimageoverlay" fullscreen>
      <v-card dark height="100%">
       
        <!-- v-layout justify-center align-center -->

        <v-row
          class="fill-height"
       
        >

         <v-toolbar dark>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="zoomimageoverlay = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-carousel v-model="curslide" hide-delimiters>
          <v-carousel-item
            max-width="100%"
            contain
            v-for="(item, i) in getContent().images"
            :key="i"
            :src="$imagekit.resize(item.img, 800)"
            reverse-transition="fade-transition"
            transition="fade-transition"
          ></v-carousel-item>
        </v-carousel>
        </v-row>

        <!-- </v-layout> -->
      </v-card>
    </v-dialog>

    <!-- -->

    <v-row>
      <v-col>
        <h2 class="light-blue--text text--darken-3 mb-4">
          {{ getContent().title }}
        </h2>
        <p v-html="getContent().description"></p>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        v-for="(item, index) in getContent().images"
        :key="index"
        class="d-flex child-flex"
        cols="4"
      >
        <v-img
          @click="zoomImage($imagekit.resize(item.img, 1920), index)"
          contain
          :src="$imagekit.resize(item.img, 200, 200)"
          aspect-ratio="1"
          class="rounded-lg"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "GalleryDetails",
  data() {
    return {
      curslide: 0,
      zoomimageoverlay: false,
      zoomimageurl: "",
      scheda: {
        titolo: "Gallery",
      },
    };
  },
  props: ["id"],
  mounted() {
    this.$store.dispatch("setCurPageAction", {
      title: "GalleryDetails",
      detail: true,
      back: "/gallery",
      bottomNavigation: true,
    });
  },
  methods: {
    getCurLanguage() {
      return this.$store.state.curlanguage;
    },

    getContent() {
      return this.$store.state.content.gallery.items[this.id];
    },
    zoomImage(url, index) {
      this.zoomimageoverlay = true;
      this.zoomimageurl = url;
      this.curslide = index;
    },
  },
};
</script>
<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-img
          contain
          :src="$imagekit.resize(getContent().bannerimg, 600)"
        ></v-img>
         
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <h2 class="light-blue--text text--darken-3 mb-4">
          {{ getContent().title }}
        </h2>
        <p v-html="getContent().description"></p>
        
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col>
        <v-list three-line>
          <template v-for="(item, index) in getContent().scenelist">
            <v-list-item :key="item.title" @click="redirect(index)">
              <v-list-item-avatar size="90" tile>
                <v-img :src="$imagekit.resize(item.img, 150)"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
                <v-list-item-subtitle
                  v-html="item.listdesc"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider
              v-if="index < getContent().scenelist.length - 1"
              :key="index"
            ></v-divider>
          </template>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Percorso",
  data() {
    return {
      scheda: {
        titolo: "titolo",
      },
      items: [],
      selectedItem: {},
    };
  },
  mounted() {
    this.$store.dispatch("setCurPageAction", {
      title: "Percorso",
      detail: false,
      back: "/",
      bottomNavigation: true,
    });
  },

  methods: {
    getCurLanguage() {
      return this.$store.state.curlanguage;
    },
    getContent() {
      return this.$store.state.content.route;
    },
    redirect(id) {
      this.$router.push("/percorsodetails/" + id);
    },
  },
};
</script>
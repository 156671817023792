<template>
  <v-app>
    <v-app-bar app color="">
      <v-app-bar-nav-icon
        v-show="isDetail() == false"
        @click.stop="drawer = !drawer"
      >
      </v-app-bar-nav-icon>

      <v-app-bar-nav-icon v-show="isDetail()" @click.stop="onBackKeyDown" >
        <v-icon>mdi-chevron-left</v-icon>
      </v-app-bar-nav-icon>

      <v-spacer></v-spacer>
      <v-toolbar-title>
        <v-img
          alt="Casa del Pescatore"
          contain
          src="./assets/logo-pescatore.png"
          width="180"
      /></v-toolbar-title>
      <v-spacer></v-spacer>

      <!--v-btn href="#" text>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn -->
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" app>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6 mb-8">
            Casa del Pescatore
          </v-list-item-title>
          <v-list-item-subtitle>
            <span
              class="pointer"
              :style="{ color: isLanguage('it') ? '#00F' : '#000' }"
              @click="changeLanguage('it')"
              >Italiano</span
            >
            <span
              class="pointer mx-4"
              :style="{ color: isLanguage('en') ? '#00F' : '#000' }"
              @click="changeLanguage('en')"
              >English</span
            >
            <span
              class="pointer"
              :style="{ color: isLanguage('de') ? '#00F' : '#000' }"
              @click="changeLanguage('de')"
              >Deutsch</span
            >
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list nav>
        <v-list-item
          v-for="item in getMenuItems()"
          :key="item.title"
          link
          @click="redirect(item.path)"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>
      <template v-slot:append>
        <div class="pa-6 text-right" @dblclick="editscenario = !editscenario">
          <span> {{ $store.getters.appVersion }}</span>
        </div>
        <div v-if="editscenario">
          
          <v-text-field v-model="sceneValue" label="Scene code"></v-text-field>
        </div>

      </template>
    </v-navigation-drawer>

    <v-main>
      <router-view />
    </v-main>

    <v-bottom-navigation app fixed grow v-model="value" color="primary" dark>
      <v-btn value="home" to="/">
        <span>{{ $vuetify.lang.t("$vuetify.home") }}</span>
        <v-icon>mdi-home-outline</v-icon>
      </v-btn>

      <v-btn value="glossario" to="/glossario">
        <span>{{ $vuetify.lang.t("$vuetify.glossario") }}</span>
        <v-icon>mdi-order-alphabetical-ascending</v-icon>
      </v-btn>

      <v-btn value="percorso" to="/percorso">
        <span>{{ $vuetify.lang.t("$vuetify.percorso") }}</span>
        <v-icon>mdi-map-marker-radius-outline</v-icon>
      </v-btn>

      <v-btn value="informazioni" to="/games">
        <span>{{ $vuetify.lang.t("$vuetify.games") }}</span>
        <v-icon>mdi-gamepad</v-icon>
      </v-btn>

      <v-btn value="gallery" to="/gallery">
        <span>{{ $vuetify.lang.t("$vuetify.gallery") }}</span>
        <v-icon>mdi-camera-outline</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    value: "",
    drawer: false,
    right: null,
    editscenario: false,
    sceneValue: "default"
  }),
  mounted() {
    const savedValue = localStorage.getItem("sceneValue");
    if (savedValue) {
      this.sceneValue = savedValue;
    }
  },
  watch: {
    sceneValue(newVal) {
      localStorage.setItem("sceneValue", newVal);
    }
  },
  methods: {
    isDetail() {
      return this.$store.getters.getCurPage.detail;
    },
    getCurLanguage() {
      return this.$store.state.curlanguage;
    },
    getMenuItems() {
      return this.$store.state.menuItems[this.$store.state.curlanguage];
    },
    changeLanguage(item) {
      this.$store.dispatch("setCurLanguageAndLoadData", item);
    },
    isLanguage(languagetocheck) {
      return this.$store.state.curlanguage == languagetocheck;
    },
    redirect(path) {
      if (this.$route.path != path) {
        this.$router.push(path);
      }
      this.drawer = false;
    },
    onBackKeyDown() {
      if (this.$router.currentRoute.path !== "/") {
        this.$router.push(this.$store.getters.getCurPage.back);
      }
    },
  },
};
</script>

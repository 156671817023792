import Vue from 'vue'
import Vuex from 'vuex'
/*import axios from "axios"
import moment from "moment"*/
import axios from 'axios'

import { mapping } from '../modules/jsonlrmapping'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    debugmode: false,
    gameList: [],
    timerAutoHome: new Date().getTime(),
    deltaSecondsAutoHome: 120,
    deltaSecondsAutoUpdate: 30,
    packageVersion: process.env.PACKAGE_VERSION || '0',
    curlanguage: 'it',
    $vuetify: null,
    latestUpdateVersion: '1.0',
    curPage: {
      title: "Home",
      bottomNavigation: true,
      detail: false
    },
    curGame: {
      "title": "Title",
      "subtitle": "Subtitle",
      "img": "",
      "items": [
      ]
    },
    content: {
      "home": {
        "title": '',
        "description": '',
        "sections": [],
        "insights": {
          title: "",
          items: []
        }
      },
      "route": {
        "title": '',
        "description": '',
        "scenelist": [],
      },
      "gallery": {
        "title": '',
        "description": '',
        "items": [],
      },
      "glossary": {
        "title": '',
        "description": '',
        "items": [],
      },
      "glossarygroup": [],
      "info": {
        "title": '',
        "description": '',
        "sections": [],
      },
      "games": {
        "title": "",
        "subtitle": "",
        "correctanswer": "",
        "correctanswerpoints": "",
        "wronganswer": "",
        "wronganswerpoints": "",
        "back": "",
        "next": "",
        "endpoints": "",
        "description": "",
        "img": "",
        "list": []
      },

    },
    menuItems: {
      'it': [
        { title: "Home", icon: "mdi-home-outline", path: "/" },
        { title: "La Casa del Pescatore", icon: "mdi-account", path: "/" },
        { title: "Il percorso", icon: "mdi-map-marker-radius-outline", path: "/percorso" },
        { title: "Galleria fotografica", icon: "mdi-camera-outline", path: "/gallery" },
        { title: "Glossario", icon: "mdi-order-alphabetical-ascending", path: "/glossario" },
        { title: "Informazioni", icon: "mdi-information-outline", path: "/informazioni" },
      ],
      'en': [
        { title: "Home", icon: "mdi-home-outline", path: "/" },
        { title: "The Fisherman's House", icon: "mdi-account", path: "/" },
        { title: "Route", icon: "mdi-map-marker-radius-outline", path: "/percorso" },
        { title: "Photo gallery", icon: "mdi-camera-outline", path: "/gallery" },
        { title: "Glossary", icon: "mdi-order-alphabetical-ascending", path: "/glossario" },
        { title: "Information", icon: "mdi-information-outline", path: "/informazioni" },
      ],
      'de': [
        { title: "Home", icon: "mdi-home-outline", path: "/" },
        { title: "Das Fischerhaus", icon: "mdi-account", path: "/" },
        { title: "Weg", icon: "mdi-map-marker-radius-outline", path: "/percorso" },
        { title: "Fotogallerie", icon: "mdi-camera-outline", path: "/gallery" },
        { title: "Glossar", icon: "mdi-order-alphabetical-ascending", path: "/glossario" },
        { title: "Information", icon: "mdi-information-outline", path: "/informazioni" },
      ],
    }
  },
  mutations: {
    setGameList(state, payload) {
      state.gameList = payload;
    },
    setResetTimer(state) {
      state.timerAutoHome = new Date().getTime();
    },
    setDebugMode(state, payload) {
      state.debugmode = payload;
    },
    setCurLanguage(state, payload) {
      state.$vuetify.lang.current = payload;
      state.curlanguage = payload;
    },
    setVuetifyRef(state, payload) {
      state.$vuetify = payload.framework;
    },
    setContentHome(state, payload) {
      state.content.home = payload;
    },
    setContentRoute(state, payload) {
      state.content.route = payload;
    },
    setContentGallery(state, payload) {
      state.content.gallery = payload;
    },
    setContentGlossary(state, payload) {
      state.content.glossary = payload;
      state.content.glossary.items.sort((a,b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0))
      let data = state.content.glossary.items.reduce((r, e) => {
        let group = e.title[0];
        if (!r[group]) r[group] = { group, children: [e] }
        else r[group].children.push(e);
        return r;
      }, {})

      state.content.glossarygroup = Object.values(data);
      state.curGlossaryContent = state.content.glossarygroup[0].children[0];
    },
    setContentInfo(state, payload) {
      state.content.info = payload;
    },
    setContentGames(state, payload) {
      state.content.games = payload;
    },
    setCurPageMutation(state, payload) {
      state.curPage = payload;
    },
    setCurGameByIndexMutation(state, payload) {
      state.curGame = state.content.games.list[payload.index];
    },
  },
  actions: {
    setGameListAction({ commit }, payload) {
      commit("setGameList", payload);
    },
    checkForUpdates({ commit }) {
      const vm = this;
      console.log('checkForUpdates', commit);
      // GENERAL CONFIG
      axios.get('https://pescatore.d40.it/jsonfeed?p_p_id=Configurable&p_p_lifecycle=2&p_p_resource_id=json&_Configurable_jsonParams={%22structureKey%22:%222954920%22,%22lng%22:%22it%22}&t=' + new Date().getTime())
        .then(response => {
          if (typeof (response.data['docs']) != 'undefined') {
            var cj = response.data.docs[0].contentJSON;
            if (cj.version !== vm.state.latestUpdateVersion) {
              console.log('Update with new version');
              vm.state.latestUpdateVersion = cj.version;
              vm.dispatch("setCurLanguageAndLoadData", '');
            } else {
              console.log('No new updates');
            }
          }
        })
    },
    resetTimer({ commit }) {
      console.log('resetTimer')
      commit("setResetTimer");
    },
    setCurGameByIndex({ commit }, payload) {
      commit("setCurGameByIndexMutation", payload);
    },
    setDebugModeAction({ commit }, payload) {
      commit("setDebugMode", payload);
    },
    setCurLanguageAndLoadData({ commit, state }, payload) {
      if (payload != '') {
        try {
          localStorage.setItem('curlanguage', payload);
        } catch (e) {
          console.log('errore local storage')
        }

        commit("setCurLanguage", payload);
      } else {
        var savedLang = 'it';
        try {
          savedLang = localStorage.getItem('curlanguage');
          if (savedLang == "undefined") {
            savedLang = 'it';
          }
        } catch {
          console.log('errore local storage')
        }
        commit("setCurLanguage", savedLang);
      }
      var sceneParam = '';
      const savedScene= localStorage.getItem("sceneValue");
        if (savedScene) {
          if (savedScene != '' && savedScene != 'default') {
            sceneParam = '%22debugscene%22:%22'+savedScene+'%22,'
          }
        }

      // HOME
      axios.get('https://pescatore.d40.it/jsonfeed?p_p_id=Configurable&p_p_lifecycle=2&p_p_resource_id=json&_Configurable_jsonParams=%7B%22structureKey%22:%222901595%22,%22lng%22:%22' + state.curlanguage + '%22%7D&v=' + state.latestUpdateVersion)
        .then(response => {
          var jsonHome = mapping.home(response.data)
          commit('setContentHome', jsonHome)
        })


      // ROUTE 
      const routeUrl = 'https://pescatore.d40.it/jsonfeed?p_p_id=Configurable&p_p_lifecycle=2&p_p_resource_id=json&_Configurable_jsonParams=%7B%22structureKey%22:%222904848%22,'+sceneParam+'%22lng%22:%22' + state.curlanguage + '%22%7D&v=' + state.latestUpdateVersion;  
      console.log("STORE >>>>>>>>>> ", routeUrl)  
      axios.get(routeUrl)
        .then(response => {
          var jsonRoute = mapping.route(response.data)
          commit('setContentRoute', jsonRoute)
        })

      // GALLERY
      axios.get('https://pescatore.d40.it/jsonfeed?p_p_id=Configurable&p_p_lifecycle=2&p_p_resource_id=json&_Configurable_jsonParams=%7B%22structureKey%22:%222905847%22,%22lng%22:%22' + state.curlanguage + '%22%7D&v=' + state.latestUpdateVersion)
        .then(response => {
          var jsonGallery = mapping.gallery(response.data)

          commit('setContentGallery', jsonGallery)
        })

      // GLOSSARY  
      axios.get('https://pescatore.d40.it/jsonfeed?p_p_id=Configurable&p_p_lifecycle=2&p_p_resource_id=json&_Configurable_jsonParams={%22structureKey%22:%222905942%22,%22lng%22:%22' + state.curlanguage + '%22}&v=' + state.latestUpdateVersion)
        .then(response => {
          var items = mapping.glossaryItems(response.data)
          var glossaryUrl = 'https://pescatore.d40.it/jsonfeed?p_p_id=Configurable&p_p_lifecycle=2&p_p_resource_id=json&_Configurable_jsonParams={%22structureKey%22:%222905937%22,%22lng%22:%22' + state.curlanguage + '%22}&v='+state.latestUpdateVersion;
          axios.get(glossaryUrl)
            .then(response => {
              var jsonGlossary = mapping.glossary(response.data, items);
              commit('setContentGlossary', jsonGlossary);
            })
        })

      // INFO  
      axios.get('https://pescatore.d40.it/jsonfeed?p_p_id=Configurable&p_p_lifecycle=2&p_p_resource_id=json&_Configurable_jsonParams=%7B%22structureKey%22:%222906487%22,%22lng%22:%22' + state.curlanguage + '%22%7D&v=' + state.latestUpdateVersion)
        .then(response => {
          var jsonInfo = mapping.information(response.data,);
          commit('setContentInfo', jsonInfo)
        })

      // GENERAL CONFIG
      // https://pescatore.d40.it/jsonfeed?p_p_id=Configurable&p_p_lifecycle=2&p_p_resource_id=json&_Configurable_jsonParams={%22structureKey%22:%222954920%22,%22lng%22:%22it%22}  


      // GAMES
      axios.get('https://pescatore.d40.it/jsonfeed?p_p_id=Configurable&p_p_lifecycle=2&p_p_resource_id=json&_Configurable_jsonParams={%22structureKey%22:%222906859%22,%22lng%22:%22' + state.curlanguage + '%22}&v=' + state.latestUpdateVersion)
        .then(response => {
          var items = mapping.gameList(response.data)
          axios.get('https://pescatore.d40.it/jsonfeed?p_p_id=Configurable&p_p_lifecycle=2&p_p_resource_id=json&_Configurable_jsonParams={%22structureKey%22:%222953903%22,%22lng%22:%22' + state.curlanguage + '%22}&v=' + state.latestUpdateVersion)
            .then(response => {
              var jsonGame = mapping.game(response.data, items);
              commit('setContentGames', jsonGame);
            })
        })

    },
    setCurPageAction({ commit }, payload) {
      commit('setCurPageMutation', payload);
    },

    initializeStore({ commit }, payload) {
      commit("setVuetifyRef", payload);
      try {
        var savedLang = localStorage.getItem('curlanguage');
        if (savedLang == "undefined") {
          savedLang = 'it';
        }

        
        
      } catch (e) {
        console.log('localStorage error')
      }

      commit("setCurLanguage", savedLang);
    }

  },
  getters: {
    getDebugMode(state) {
      return state.debugmode;
    },
    getCurLanguage(state) {
      return state.curlanguage;
    },
    
    getMenuItems(state) {
      return state.menuItems[state.curlanguage];
    },
    getGlossaryById: (state) => (id) => {
      var ret = {
        title: 'not found'
      }
      for (var i = 0; i < state.content.glossary.items.length; i++) {
        if (state.content.glossary.items[i].id == id) {
          ret = state.content.glossary.items[i];
        }
      }
      return ret
    },
    getCurrentGame(state) {
      return state.curGame;
    },
    getCurPage(state) {
      return state.curPage;
    },
    getGameList(state) {
      return state.gameList;
    },
    getGames(state) {
      console.log("getGames")
      if (state.gameList.length > 0) {
        var games = {
          list: []
        };
        for (var i = 0; i < state.content.games.list.length; i++) {
          if (state.gameList.indexOf(state.content.games.list[i].id) >= 0) {
            games.list.push(state.content.games.list[i]);
          }
        }
        if (games.list.length > 0) {
          return games;
        } else {
          return state.content.games;
        }

      } else {
        return state.content.games;
      }

    },
    appVersion: (state) => {
      return state.packageVersion
    }
  }
})
<template>
  <!-- v-container fill-height style="background-color: #ccccbb" -->
  <v-container fill-height>
    <v-row>
      <!-- sx col show image and progress -->
      <v-col cols="12">
        <v-card>
          <v-card-title
            style="background-color: #1e75a0"
            class="rounded-lg pa-6"
          >
            <v-img :src="$imagekit.resize(getContent().items[answerNum].img, 600, 600)" ></v-img>
          </v-card-title>
        </v-card>

        <v-progress-linear
          class="rounded-lg mt-8 mb-4"
          :value="calcProgress()"
          color="green"
          height="30"
        ></v-progress-linear>
        <span class="text-h5">
          {{ answerNum + 1 }} / {{ totalAnswers() }}
        </span>
      </v-col>

      <!-- dc col show quest, answers, result and buttons -->
      <v-col cols="12">
        <!-- domanda -->
        <v-row>
          <v-col class="text-center">
            <v-sheet color="#F5F5F5" elevation="0" rounded class="pa-4">
              <p v-html="getContent().items[answerNum].description" class=" text-body-1"></p>
            </v-sheet>
          </v-col>
        </v-row>
        <!-- risposte -->
        <v-row v-show="showResult==false">
          <v-col
          
            :cols="getCols()"
            v-for="(item, index) in getContent().items[answerNum].answers"
            :key="index"
            class="d-flex child-flex text-center"
          >
            <v-sheet
            
              color="#719C87"
              elevation="2"
              rounded
              class="white--text px-3 py-2 text-h6"
              width="100"
              @click="selectAnswer(item)"
              :disabled="showResult"
            >
              {{ item.title }}
            </v-sheet>

            
          </v-col>
        </v-row>

        <!-- risultato -->
        <v-row v-show="showResultOK">
          <v-col class="pa-4 text-center text-h5">
            <v-icon class="green--text text-h5 mr-4">mdi-check-circle</v-icon
            ><span class="green--text" v-html="resultExplanation"></span>
            <p class="mt-3" v-html="resultExplanationPoints"></p>
          </v-col>
        </v-row>
        <v-row v-show="showResultWrong">
          <v-col class="pa-4 text-center text-h5">
            <v-icon class="red--text text-h5 mr-4">mdi-close-circle</v-icon
            ><span class="red--text" v-html="resultExplanation"></span>
            <p class="mt-3" v-html="resultExplanationPoints"></p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <!-- v-col>
        <v-btn
          @click="goToGamesPage()"
          x-large
          elevation="2"
          color="secondary"
          class="white--text"
          ><v-icon class="ma-2"> mdi-arrow-left </v-icon></v-btn
        >
      </v-col -->
      <v-col class="d-flex align-end flex-column">
        <v-btn
          v-show="enableNext"
          @click="nextQuestion()"
          x-large
          elevation="2"
          color="secondary"
          class="white--text"
          > {{$vuetify.lang.t("$vuetify.prossima")}} <v-icon class="ma-2"> mdi-arrow-right </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <!-- code>
      {{getContent()}}
    </code -->
  </v-container>
</template>

<script>
export default {
  name: "GameRunning",
  data() {
    return {
      totPoints: 0,
      resultCorrectAnswer: "",
      resultExplanation: "",
      resultExplanationPoints: "",
      correctanswer: "",
      correctanswerpoints: "",
      wrongAnswer: "",
      wrongAnswerPoints: "",
      answerNum: 0,
      enableNext: false,
      showResult: false,
      showResultOK: false,
      showResultWrong: false,
      scheda: {
        titolo: "titolo",
      },
    };
  },
  mounted() {
    this.$store.dispatch("setCurPageAction", {
      title: "GameRunning",
      detail: false,
      back: "/games",
      bottomNavigation: false,
    });

    this.correctanswer = this.$store.state.content.games.correctanswer;
    this.correctanswerpoints =
      this.$store.state.content.games.correctanswerpoints;
    this.wronganswer = this.$store.state.content.games.wronganswer;
    this.wronganswerpoints = this.$store.state.content.games.wronganswerpoints;
  },
  methods: {
    calcProgress() {
      return (
        (100 / this.$store.state.curGame.items.length) * (this.answerNum + 1)
      );
    },
    getCols() {
      if (this.$store.state.curGame.answersLayout == "col2") {
        return 6;
      } else {
        return 12;
      }
    },
    totalAnswers() {
      return this.$store.state.curGame.items.length;
    },
    getCurLanguage() {
      return this.$store.state.curlanguage;
    },
    getContent() {
      return this.$store.state.curGame;
    },
    getGameConfig() {
      return this.$store.state.content.games;
    },
    setCurGame(index) {
      this.$store.dispatch("setCurGame", { index: index });
    },
    goToGamesPage() {
      this.$router.replace({ path: "/games" });
    },
    selectAnswer(selectAnswer) {
      this.resultCorrectAnswer = "";
      for (
        var i = 0;
        i < this.$store.state.curGame.items[this.answerNum].answers.length;
        i++
      ) {
        var answer = this.$store.state.curGame.items[this.answerNum].answers[i];
        if (answer.points > 0) {
          this.resultCorrectAnswer = answer.title;
        }
      }

      if (selectAnswer.points == 0) {
        this.resultExplanation = this.wronganswer.replace(
          "#A",
          this.resultCorrectAnswer
        );
        this.resultExplanationPoints = this.wronganswerpoints;
        this.showResultWrong = true;
      } else {
        this.resultExplanation = this.correctanswer;

        this.resultExplanationPoints = this.correctanswerpoints.replace(
          "#N",
          selectAnswer.points
        );

        this.showResultOK = true;
      }
      this.totPoints = this.totPoints + selectAnswer.points;
      this.showResult = true;

      this.enableNext = true;
    },
    nextQuestion() {
      this.$store.dispatch("resetTimer");
      this.enableNext = false;
      if (this.answerNum + 1 < this.$store.state.curGame.items.length) {
        this.answerNum++;

        this.showResult = false;
        this.showResultWrong = false;
        this.showResultOK = false;
      } else {
        this.$router.replace({ path: "/gameresult/" + this.totPoints });
      }
    },
  },
};
</script>
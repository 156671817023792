<template>
  <v-container fill-height >
    <v-row>
      <v-col class="text-center text-h4" cols="12">
        <p v-html="resultTitle"></p>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
export default {
  name: "GameResult",
  data() {
    return {
      resultTitle: '',
      resultDescription:'',
      scheda: {
        titolo: "titolo",
      },
    };
  },
  props: ["points"],
  mounted() {
    this.$store.dispatch("setCurPageAction", {
      title: "GameResult",
      detail: false,
      back: "/games",
      bottomNavigation: false,
    });
    // #TR#
    
    this.resultTitle = this.$vuetify.lang.t("$vuetify.resultTitle").replace("%P", this.points);
    //this.resultDescription = 'Mario è molto soddisfatto del tuo lavoro';
    this.resultDescription = '';
  },
  methods: {
    getCurLanguage() {
      return this.$store.state.curlanguage;
    },
    getContent() {
      return this.$store.state.curGame;
    },
    goToGamesPage() {
      this.$router.replace({ path: "/games" });
    },
   
    
  },
};
</script>